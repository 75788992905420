/**
 * import
 *
 * @version 0.1 (2021.05.17 : usayama)
 * @version 0.2 (2021.05.20 : usayama) // Label 追加, ReactMarkdown 追加
 * @version 0.3 (2021.06.21 : usayama) // 実装
 */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IoMailOutline } from 'react-icons/io5'
import { useForm } from 'react-hook-form'
// conf, action
import { VALIDATION_PATTERNS } from '@config/validation.patterns'
import authActions from '@redux/auth/actions'
// components
import InputGroup from '@components/Form/InputGroup'
import Button from '@components/UIKit/Button'
// containers
// helper
// import { isEmpty } from '@lib/helpers'
// style, img, elm
// const
const { initSignUp, signUp } = authActions

/** 
 * export : 
 * 
 * @version 0.1 (2021.05.17 : usayama_dx)
*/
export default function SignUpContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.17 : usayama)
   * @version 0.2 (2021.05.19 : usayama) // name, password 追加, setError 追加
   * @version 0.3 (2021.05.20 : usayama) // agreement, Terms 追加
   * @version 0.4 (2021.06.21 : usayama_dx) // メール認証用に変更
   */
  const { handleSubmit, 
          register, 
          formState, 
          setError,
          errors } = useForm({ mode: 'onChange' })
  const dispatch = useDispatch()
  const { signUpResult } = useSelector(state => state.Auth)

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.05.17 : usayama_dx)
   */
  const [ initPage, setInitPage ] = React.useState(false)

  /** 
   * event : 
   * 
   * @version 0.1 (2021.05.17 : usayama)
   * @version 0.2 (2021.05.18 : usayama) // initSignUp 追加
   */
  const onSubmit = (d) => {
    dispatch(initSignUp())
    dispatch(signUp(d))
  }

  /** 
   * useEffect
   * 
   * @version 0.1 (2021.05.19 : usayama)
   */
  React.useEffect(() => {
    if(signUpResult && signUpResult.errors) {
      Object.keys(signUpResult.errors).map((key) => setError(key, { type: "manual", message: signUpResult.errors[key] }))
    }
  }, [signUpResult, setError])

  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.05.17 : usayama)
   */
  React.useEffect(() => {
    if(!initPage) {
      // document.getElementById("isFocus").focus()
      setInitPage(true)
    }
  }, [initPage])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.17 : usayama_dx)
   * @version 0.2 (2021.05.19 : usayama) // name, password 追加, VALIDATION_PATTERNS 導入
   * @version 0.3 (2021.05.20 : usayama_dx) // 規約同意文追加, ReactModal 追加
   * @version 0.4 (2021.06.21 : usayama_dx) // メール認証用に変更
   */
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup
          label={<IoMailOutline size={25} />}
          placeholder="placeholder.email"
          type="email"
          name="email"
          feedback={errors && errors.email}
          errors={errors && errors.email}
          className={errors.email && "is-invalid"}
          inputClassName={errors.email && "is-invalid"}
          inputRef={register({ required: true, pattern: VALIDATION_PATTERNS.email })}
         />
        <div className="form-action">
          <Button type="submit" color="success" label="signup" disabled={!formState.isValid} />
        </div>
      </form>
    </div>
  )
}

/**
 * import
 *
 * @version 0.1 (2021.05.17 : usayama)
 * @version 0.2 (2021.06.28 : usayama) // Seo 追加
 */
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// conf, action
// components
import Seo from '@components/Tools/Seo'
import ModalLoading from '@components/UIKit/ModalLoading'
import Alert from '@components/UIKit/Alert'
import LabelHtml from '@components/Typo/LabelHtml'
// containers
import LayoutContainer from '@containers/LayoutContainer'
import AuthContainer from '@containers/AuthContainer'
import SignUpContainer from '@containers/SignUpContainer'
// helper
// style, img, elm
// const

/**
 * const
 *
 * @version 0.1 (2021.05.17 : usayama)
 */
const SignUp = () => {

	/**
	 * const
	 *
	 * @version 0.1 (2021.05.17 : usayama)
	 * @version 0.2 (2021.05.19 : usayama) // signUpResultStatus に変更
	 */
  const { loadSignUp, signUpResultStatus } = useSelector(state => state.Auth)
  const { t } = useTranslation()

	/**
	 * return
	 *
	 * @version 0.1 (2021.05.17 : usayama)
	 * @version 0.2 (2021.05.19 : usayama) // signUpResultStatus に変更
	 * @version 0.3 (2021.06.28 : usayama) // Seo 追加
	 */
	return (
	    <LayoutContainer>
	    	<Seo title={t('title.signup')} />
				<ModalLoading isLoading={loadSignUp} />
      	<AuthContainer titleLabel="title.signup">
      		{ signUpResultStatus && <Alert status={signUpResultStatus} label={"message." + signUpResultStatus + ".signup"} isOpen={true} closeBt={true} /> }
      		{ signUpResultStatus !== "success" && <p className="mb-4"><LabelHtml label="text.signup" /></p> }
      		{ signUpResultStatus !== "success" && <SignUpContainer /> }
      	</AuthContainer>
	    </LayoutContainer>
	)
}

/**
 * export
 *
 * @version 0.1 (2021.05.17 : usayama)
 */
export default SignUp
